<template>
    <div>
    <b-card-body class="bg-white card-body my-3 p-0 roun shadow" style="border-radius:15px;">
      <b-media
              no-body
              tag="li"
              class="px-2 mb-2"
              style="color:white;border-radius:15px;background: linear-gradient(92.88deg, #F11240 4.2%, #BF0244 97.09%);position:relative;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);border: #FED532 3px solid;"
            >
              <b-media-aside class="align-self-cente mr-1">
                <b-img
                  :src="require('@/assets/avatar.png')"
                  alt="Media Aside"
                  style="width:3rem;height:3rem;"
                  class="m-2"
                ></b-img>
              </b-media-aside>
              <b-media-body class="align-content-between align-self-center d-flex flex-wrap">
                  <div>
                <h5 class="mt-2" style="text-align:left;color:white;">
                  Skor Saya:
                </h5>
                  </div>
                <div class="text-right">
                    <h5 class="mt-2 font-weight-bold pr-2 position-absolute" style="right: 1rem;">{{ point }}</h5>
                </div>
              </b-media-body>
            </b-media>
            <h6 class="pb-2 d-block">Yuk tambah lagi dong SKORnya!!</h6>
    </b-card-body>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
      ...mapState([
        'point'
      ])
    }
}
</script>