<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6">
    <Skor />
    <Liststore />
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
import Liststore from '@/components/ListStore.vue'
import Header from '@/components/Header.vue'
import Skor from '@/components/Skor.vue'
export default {
  name: 'Redeem',
  components: {
    Liststore,
    Header,
    Skor,
  },
  data() {
    return {
      titleHeader: "CUAN TOKEN STORE",
      showToken: true,
      showBg: true,
      store: [],
    };
  },
  computed: {
    ...mapState([
            'coin',
        ])
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
